import React from 'react';

import Unsubscribe from '../components/alerts/unsubscribe/unsubscribe';
import Layout from '../components/global/layout/layout';
import { PageBase } from '../context/global-context';
import { AlertUnsubscribeData, AlertUnsubscribeLocalizationData } from '../types/alerts';
import { PageContext } from '../types/page';
import { getLocales, LocaleEnum, useI18n } from '../utils/i18n';

type AlertUnsubscribeContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const AlertUnsubscribe: React.FC<{ 
  token: string,
  pageContext: AlertUnsubscribeContext 
}> = ({ token, pageContext }) => {
  const { translations, locale, slug } = pageContext;
  
  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: AlertUnsubscribeLocalizationData[] = [];

  locales.forEach((l) => {
    let slug = i18n.t('alert.unsubscribe.slug', l);
    slug = slug ? slug : 'alert/unsubscribe';

    localizationsData.push({
      attributes: {
        slug: `${slug}/${token}`,
        locale: l,
      },
    });
  });

  const instance: AlertUnsubscribeData = {
    attributes: {
      slug: `${slug}/${token}`,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <PageBase translations={translations} instance={instance}>
      <Layout>
        <main>
          <Unsubscribe token={token} />
        </main>
      </Layout>
    </PageBase>
  );
};

export default AlertUnsubscribe;
