import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import GlobalContext from '../../../context/global-context';
import { Link } from 'gatsby';
import { LocaleType } from '../../../utils/i18n';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';

import './unsubscribe.scss';
import Loading from '../../global/loading/loading';

const Unsubscribe: React.FC<{
  token: string;
}> = ({ token }) => {
  const { i18n } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      axios.delete(`${process.env.GATSBY_STRAPI_URL}/api/alerts/unsubscribe/${token}`)
      .then(response => {
        if (response.status === 204) {
          setSuccess(true);
        }
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  }, []);

  let opportunitiesSlug = i18n?.t('opportunities.slug');
  opportunitiesSlug = opportunitiesSlug ? opportunitiesSlug : 'opportunities';

  if (loading)
  return <div className="unsubscribe-confirmation-page-wrapper"><Loading size='large' /></div>;

  return (
    <div className="unsubscribe-confirmation-page-wrapper">
        {!loading && !success && (
          <>
            <h2>{i18n?.t('alert.unsubscribe.error.title')}</h2>
            <p className='unsubscribe-message unsubscribe-body'>{i18n?.t('alert.unsubscribe.error.body')}</p>
          </>
        )}
        {!loading && success && (
          <>
            <h2>{i18n?.t('alert.unsubscribe.confirmation.title')}</h2>
            <p className='unsubscribe-message unsubscribe-body'>{i18n?.t('alert.unsubscribe.confirmation.body1')}</p>
            <p className='unsubscribe-body'>{i18n?.t('alert.unsubscribe.confirmation.body2')} <a href={`/${currentLocale}/${opportunitiesSlug}`}>{i18n?.t('alert.unsubscribe.link.opportunities.text')}</a></p>
          </>
        )}      

        <span className="filled">
          <Link
            to={`/${currentLocale}`}
            aria-label={'Home'}
            className="home-link btn btn-primary"
          >
            {i18n?.t('link.home.text')}
          </Link>
        </span>
    </div>
  );
};

export default Unsubscribe;
